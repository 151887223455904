import { usePathname } from "next/navigation";
import style from "../styles/NavLink.module.css";
import Link from "next/link";

function NavLink({ href, name, setIsOpenMenu }) {
  const path = usePathname();
  const currentPath = path.slice(1);
  return (
    <li className="max-[960px]:text-center max-[960px]:mt-5 md:text-lg font-[500] text-[18px] eng-txt">
      <Link
        href={`/${href}`}
        onClick={(e) => setIsOpenMenu(false)}
        className={`${currentPath == href && style.active}`}
      >
        {name}
      </Link>
    </li>
  );
}

export default NavLink;
