import { createClient } from "contentful";

export const getServices = async () => {
  const client = createClient({
    space: process.env.CONTENTFUL_SPACE_ID,
    accessToken: process.env.CONTENTFUL_ACCESS_KEY,
  });

  const services = await client.getEntries({ content_type: "services" });
  return services.items;
};
