import Layout from "@/components/Layout";
import "@/styles/globals.css";
import NextProgress from "next-progress";
import { Analytics } from "@vercel/analytics/react";

export default function App({ Component, pageProps }) {
  return (
    <>
      <NextProgress
        delay={300}
        height={4}
        color="#FAC211"
        options={{ showSpinner: false }}
      />

      <Layout>
        <Component {...pageProps} />
        {/* <Analytics /> */}
      </Layout>
    </>
  );
}
