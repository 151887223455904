"use client";

import NavLink from "./NavLink";
import logo from "@/assets/image/DZK-Logo.png";
import Link from "next/link";
import { useState } from "react";

import { FiMenu, FiX } from "react-icons/fi";
import Image from "next/image";

function Header() {
  const [isOpenMenu, setIsOpenMenu] = useState();
  const toggleMenu = (e) => {
    e.preventDefault();
    setIsOpenMenu(!isOpenMenu);
  };
  return (
    <header className="bg-[#02020B] w-[100vw] z-10 fixed top-0 py-[15px]">
      <div className="mx-[28px] md:mx-[40px] flex justify-between items-center">
        <Link href={"/"}>
          <div className="flex items-center gap-[0.5rem]">
            <Image
              className="w-[52px!important] md:w-[73px]"
              // src={`${process.env.NEXT_PUBLIC_VERCEL_URL}/assets/image/DZK-Logo.png`}
              src={logo}
              alt="Digital Zeekwet Logo"
              width={52}
              height={52}
              priority={true}
            />
            <div className="font-semibold text-[1rem] leading-[19px] md:text-2xl">
              <p className="eng-txt text-[#EFF1F4]">Digital</p>
              <p className="eng-txt text-[#FACB39]">ZeeKwet</p>
            </div>
          </div>
        </Link>

        <div>
          <ul
            className={`menu-bar inline-flex items-center gap-10 list-none text-[#E6E6E6]   max-[960px]:bg-[#02020B] max-[960px]:block max-[960px]:absolute max-[960px]:z-[-1]  ${
              isOpenMenu && "show-menu"
            }`}
          >
            <NavLink name="Home" href="#home" className=".menu-home" setIsOpenMenu={setIsOpenMenu}/>
            <NavLink name="Services" href="#services" setIsOpenMenu={setIsOpenMenu}/>
            <NavLink name="Projects" href="portfolio" setIsOpenMenu={setIsOpenMenu}/>
            <NavLink name="About" href="#about" setIsOpenMenu={setIsOpenMenu}/>
            <NavLink name="Blog" href="blog" setIsOpenMenu={setIsOpenMenu}/>

            <div className="flex text-center max-[960px]:mt-10 mx-auto">
              <Link href="https://m.me/digitalzeekwet" className="mx-auto">
                <button className="eng-txt text-[#000000] font-[700] rounded-xl bg-[#fac211] text-base text-[15px] px-[14.5px] py-[10px]">
                  GET IN TOUCH
                </button>
              </Link>
            </div>
          </ul>

          <label className="text-2xl text-[#E6E6E6] min-[961px]:hidden">
            <button className="mr-3" onClick={toggleMenu}>
              {!isOpenMenu ? <FiMenu /> : <FiX />}
            </button>
          </label>
        </div>
      </div>
    </header>
  );
}

export default Header;
