import { getServices } from "@/libs/getServices";
import { createClient } from "contentful";
import Link from "next/link";
import { useEffect, useState } from "react";
import { BsFacebook, BsLinkedin, BsYoutube } from "react-icons/bs";
import { FaArrowRight } from "react-icons/fa";

const Footer = () => {
  const [services, setServices] = useState();
  const loadServices = async () => {
    const resultServices = await getServices();
    setServices(resultServices);
  };

  useEffect(() => {
    loadServices();
  }, []);

  return (
    <footer>
      <div className="container-fluid eng-txt mx-[24px] sm:mx-[48px]  lg:mx-[72px]">
        <div className="flex flex-col lg:flex-row lg:justify-between lg:items-baseline">
          <p className="basis-[50%] font-semibold text-[32px] leading-[42px] text-[#C5CAD0] mb-6">
            Let’s work together with our teams.
          </p>
          <div className="flex flex-col">
            <Link href="mailto:info@digitalzeekwet.com" target="_blank">
              <div className="flex basis-[50%] border-b-[1px] border-b-[#DDF399] pb-[10px] lg:pb-5 items-center justify-between lg:gap-4">
                <p className="text-[16px] font-semibold leading-[42px] lg:text-[24px] text-[#C5CAD0]">
                  info@digitalzeekwet.com
                </p>
                <FaArrowRight className="text-[#F7FFC9] " />
              </div>
            </Link>
          </div>
        </div>
        <div className="flex flex-col lg:flex-row mt-[2.5rem] mb-6 lg:mb-[25px]">
          <div className="flex basis-[50%] flex-col gap-6">
            <h4 className="text-[20px] font-semibold leading-[24px] text-[#C5CAD0]">
              Contact information
            </h4>
            <p className="text-[16px] font-medium leading-[22px] text-[#76768B]">
              N-E5, Market Garden, Myeik 14051, Myanmar(Burma)
            </p>
            <Link href="mailto:info@digitalzeekwet.com" target="_blank">
              <p className="text-[16px] font-medium leading-[22px] text-[#76768B]">
                info@digitalzeekwet.com
              </p>
            </Link>
            <div className="flex ">
              <Link href="tel:+959428770634">
                <span className="text-[16px] font-medium leading-[22px] text-[#76768B]">
                  09-428770634,
                </span>
              </Link>
              &nbsp;
              <Link href="tel:+959442504250">
                <span className="text-[16px] font-medium leading-[22px] text-[#76768B]">
                  09-442504250
                </span>
              </Link>
            </div>
            <div className="flex gap-4 t">
              <div>
                <Link
                  href={"https://www.facebook.com/digitalzeekwet"}
                  target="_blank"
                >
                  <BsFacebook fontSize={38} color="#2563EB" />
                </Link>
              </div>
              <div>
                <Link
                  href={"https://www.youtube.com/@digitalzeekwet4260"}
                  target="_blank"
                >
                  <BsYoutube fontSize={38} color="#FF0000" />
                </Link>
              </div>
              <div>
                <Link
                  href={"https://www.linkedin.com/company/digital-zeekwet"}
                  target="_blank"
                >
                  <BsLinkedin fontSize={38} color="#0A66C2" />
                </Link>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-6 lg:justify-between lg:flex lg:flex-row basis-[50%] mt-[2rem] lg:gap-[25px] lg:mt-0 ">
            <div className="flex flex-col  gap-6 lg:gap-4 xl:gap-6">
              <h3 className="text-[18px] text-[#C5CAD0]">All Services</h3>
              {services &&
                services.map((service) => {
                  return (
                    <Link
                      key={service.fields.slug}
                      href={"/services/" + service.fields.slug}
                    >
                      <p className="text-[16px] text-[#76768B] font-medium">
                        {service.fields.displayName}
                      </p>
                    </Link>
                  );
                })}
            </div>
            <div className="flex flex-col  gap-6 lg:gap-4 xl:gap-6">
              <h3 className="text-[18px] text-[#C5CAD0] font-semibold">
                Resources
              </h3>
              <Link href={"/portfolio"}>
                <p className="text-[16px] text-[#76768B] font-medium">
                  Portfolio
                </p>
              </Link>
              <Link href={"/free-consultation"}>
                <p className="text-[16px] text-[#76768B] font-medium">
                  Get Free Service
                </p>
              </Link>
              <Link href={"/free-book"}>
                <p className="text-[16px] text-[#76768B] font-medium">
                  Download free E-book
                </p>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <p className="text-[#76768B]  py-2 text-[16px] text-center">
        ©{new Date().getFullYear()} Digital ZeeKwet
      </p>
    </footer>
  );
};

export default Footer;
